<template>
  <div id="portal">
    <component
      v-loading="$plugin.history.loading"
      :is="$plugin.history.content"
      :resourceType="resourceType"
      :resourceServer="resourceServer"
      :title="title"
      :parameter="$plugin.history.parameter"
      :instancePath="instancePath"
      :entryPath="entryPath"
      :templates="templates"
    ></component>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      title: "Notification Hub",
      resourceType: "NotificationHub",
      resourceServer: "https://azure_east-asia.resource-hub.j1.business",
      instancePath: "components/NotificationHub-Portal.vue",
      entryPath: "Portal.vue",
      templates: {
        "Blank Template": "Portal-Create.vue",
      },
    };
  },
  created: function () {
    this.resourceServer = process.env.VUE_APP_ResourcerHub_Endpoint;
    this.$plugin.authorization.server.endpoint =
      process.env.VUE_APP_AuthorizationHub_Endpoint;
    this.$plugin.authorization.server.subscriptionToken =
      process.env.VUE_APP_AuthorizationHub_SubscriptionToken;
    this.$plugin.authorization.server.solutionId =
      process.env.VUE_APP_AuthorizationHub_SolutionId;

    console.log(process.env);
    if (this.$plugin.authorization.isAuthorization) {
      this.$plugin.history.push(
        "sdk/ResourceHub-Portal/ResourceHub-Portal.vue"
      );
    } else {
      this.$plugin.history.push(
        "sdk/AuthorizationHub-Portal/AuthorizationHub-Portal.vue"
      );
    }
  },
};
</script>

<style>
@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("./assets/font/sanfranciscodisplay-regular-webfont.woff");
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  font-family: "San Francisco", Roboto, sans-serif !important;
}
</style>

<style scoped>
#portal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: default;
  overflow: hidden !important;
}

#portal > div {
  width: 100%;
  height: 100%;
}
</style>